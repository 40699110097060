<template>
    <v-form class="mobile-number-form" v-model="isValid" ref="form" @submit.prevent="submitForm">
        <div class="relative h-64 w-full mb-3">
            <div class="narrator-container mt-8 z-10">
                <img class="narrator" src="@/assets/images/language-bubbles.svg" />
            </div>
            <div
                class="absolute ml-1 bg-grey-2 rounded-lg shadow-xl-light"
                style="top: 125px; height: 102px; width: 98%"
            >
                <h1 class="mb-3 p-6 ml-1 mt-4 font-bold font-sans text-lg">Have a Question?</h1>
            </div>
        </div>
        <!-- 01/04/25 - A - changed Enter your mobile number below to talk to a team member -->
        <h5 class="flex flex-row justify-center text-sm text-center mx-2 mb-6 leading-4 font-sans">
            Enter your mobile number below to start a chat session
        </h5>
        <!-- TODO: haveg a look at the difference between the 2 -->
        <!-- <vue-tel-input
            ref="phoneInput2"
            class="mb-4"
            defaultCountry="AU"
            v-model="value"
            :rules="[required, numbersOnly]"
        ></vue-tel-input> -->
        <MazPhoneNumberInput
            ref="phoneInput"
            class="mb-4"
            no-flags
            show-code-on-list
            size="sm"
            default-country-code="AU"
            v-model="form.fullPhone"
            :rules="[required, numbersOnly]"
            @update="phoneNumberEvent = $event"
        ></MazPhoneNumberInput>
        <v-btn
            class="font-sans text-xl bg-grey infoLight2--text w-full py-6 rounded-full"
            depressed
            type="submit"
            color="info"
            style="font-size: 0.975rem"
            :loading="loading"
            >Start Chatting</v-btn
        >
    </v-form>
</template>

<script>
import {FormRules} from '@/components/shared/mixins/formMixins'
import {MazPhoneNumberInput} from 'maz-ui'
import {mapGetters} from 'vuex'
// import {VueTelInput} from 'vue-tel-input'

export default {
    name: 'MobileNumberForm',
    components: {MazPhoneNumberInput}, //, VueTelInput},
    mixins: [FormRules],
    props: {
        form: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isValid: true,
            phoneNumberEvent: {}
        }
    },
    mounted() {
        this.clearValidate()
        this.phoneNumberEvent = {}
        this.form.phone = ''
    },
    computed: {
        ...mapGetters(['customerAppOrigin'])
    },
    methods: {
        submitForm() {
            this.clearValidate()
            this.$refs.form.validate()
            if (!this.isValid) return

            this.$emit('submit')
        },
        validate() {
            this.isValid = this.$refs.form.validate()
            this.isValid = this.isValid && this.$refs.phoneInput?.isValid
            return this.isValid
        },
        clearValidate() {
            this.isValid = true
            this.$refs.form.reset()
            return this.isValid
        }
    },
    watch: {
        phoneNumberEvent() {
            if (this.phoneNumberEvent && this.phoneNumberEvent?.formatInternational != null) {
                this.form.phone = this.phoneNumberEvent.formatInternational
                    .replace(/\+/g, '')
                    .replace(/ /g, '')
            }
        }
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
